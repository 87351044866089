<template>
	<div id="loyout" class="screen-wrapper">
		<div class="screen" id="screen">
			<div class="vehicles-page">
				<!-- <vehiclesCom/> -->
				<transition name="main" mode="out-in">
					<router-view :key="$route.fullPath"></router-view>
				</transition>
				<!-- <div class="fullScreen flex">
                <img v-show="showFullScreen" src="@/assets/images/index/fullscreen.png" @click="clickFullScreen"/>
                <img v-show="!showFullScreen" src="@/assets/images/index/fullscreen-exit.png" @click="clickExit"/>
            </div> -->
			</div>
			<img v-if="isReading" class="shuziren" src="@/assets/images/exposition/humanrobat-1.png" alt="">
		</div>
	</div>
</template>
<script>
	// import vehiclesCom from './vehicles/vehiclesCom/index.vue'
	export default {
		components: {
			// vehiclesCom
		},
		data() {
			return {
				showFullScreen: true,
				isReading: false
			};
		},
		mounted() {
			if (sessionStorage.getItem('fromScreen') == 2 && this.$route.path !== '/chonghuan-monitor') {
				this.$bus.$on('readingFn', (data) => {
					this.isReading = data.isReading
				})
			}
			let that = this;
			//初始化自适应  ----在刚显示的时候就开始适配一次
			that.handleScreenAuto();
			//绑定自适应函数   ---防止浏览器栏变化后不再适配
			window.onresize = function() {
				that.handleScreenAuto();
			};
			if (sessionStorage.getItem('fromScreen') !== 2) {
				this.isReading = false
			}
		},
		methods: {
			//数据大屏自适应函数
			handleScreenAuto() {
				const designDraftWidth = 3840; //设计稿的宽度
				const designDraftHeight = 1590; //设计稿的高度
				//根据屏幕的变化适配的比例
				const scale = document.documentElement.clientWidth / document.documentElement.clientHeight <
					designDraftWidth / designDraftHeight ?
					(document.documentElement.clientWidth / designDraftWidth) :
					(document.documentElement.clientHeight / designDraftHeight);
				//缩放比例

				console.log('777777777777777777777777777777777777777666666666666')
				console.log(scale)

				document.querySelector('#screen').style.transform = `scale(${scale+0.005}) translate(-50%)`;
				let scaleY = parseFloat(localStorage.getItem('newBigScreen::scaleY')) || 1
				if (sessionStorage.getItem('fromUser') == 'guizhou') {
					document.querySelector('#screen').style.transform = `scale(${scale+0.005}, ${scale * 1.6}) translate(-50%)`;
				} else {
					document.querySelector('#screen').style.transform =
						`scale(${scale+0.005}, ${scale * scaleY}) translate(-50%)`;
				}
			},
			//全屏
			clickFullScreen() {
				const docElm = document.documentElement;
				if (docElm.requestFullscreen) {
					docElm.requestFullscreen();
				} else if (docElm.msRequestFullscreen) {
					docElm.msRequestFullscreen();
				} else if (docElm.mozRequestFullScreen) {
					docElm.mozRequestFullScreen();
				} else if (docElm.webkitRequestFullScreen) {
					docElm.webkitRequestFullScreen();
				}
				this.showFullScreen = !this.showFullScreen;
			},
			//点击退出全屏
			clickExit() {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.webkitCancelFullScreen) {
					document.webkitCancelFullScreen();
				}
				this.showFullScreen = !this.showFullScreen;
			},
		},
		destroyed() {
			this.$bus.$off('readingFn')
		}
	};
</script>

<style lang="scss" scoped>
	.screen-wrapper {
		width: 100%;
		height: 100%;
		background: #000;

		.screen {
			overflow: hidden;
			display: inline-block;
			width: 3840px;
			height: 1592px;
			transform-origin: 0 0;
			position: absolute;
			left: 50%;

			.shuziren {
				position: absolute;
				right: 780px;
				bottom: 0;
				z-index: 15;
			}
		}
	}

	.vehicles-page {
		width: 100%;
		height: 100%;
		position: relative;

		.fullScreen {
			position: fixed;
			width: 30px;
			height: 30px;
			top: 60px;
			right: 20px;
			z-index: 10;
		}
	}

	#expositionMain {
		height: 100%;
		// padding-top: 129px;
		// box-sizing: border-box;
	}
</style>